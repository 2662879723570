<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
  <section>
    <b-container>
      <b-row>
        <!-- Accordions -->
        <b-col md="12" class="mx-auto">
            <div class="question-collapse">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-0 shadow-none border">
                <b-card-header header-tag="header" class="bg-primary" role="tab" v-b-toggle.accordion-1>
                  <h4 class="mb-0 w-100 text-white"> Select Month for attachment <i class="bi-chevron-down rotate-icon"></i> </h4>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <div class="mb-1" v-if = "months.length > 0" v-for="(attachmentMonth, index) in months" v-bind:key="index"><b-link :to="'/student-course-video/'+attachmentMonth.month_id"><i class="bi-diamond-fill mr-1"></i> {{attachmentMonth.month.name}} ({{attachmentMonth.total_videos}})</b-link></div>
                    
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  </b-overlay>
</template>

<style scoped>
    .question-radio input[type="radio"]{
    display: none;
}
.question-radio label {
    box-shadow: 0px 2px 11px rgb(69 65 164 / 6%), 0px 4px 10px rgb(31 37 89 / 7%);
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #349F1A;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
}
.question-radio label .option-count {
    border-radius: 50%;
    box-shadow: 0px 2px 11px rgba(69, 65, 164, 0.06), 0px 4px 10px rgba(31, 37, 89, 0.07);
    width: 40px;
    height: 40px;
    border: 1px solid #ddd;
    line-height: 40px;
    text-align: center;
}
.question-radio input:checked + label {
    background-color: #349F1A;
    color: #ffffff !important;
}
.question-collapse .card-header{
    cursor: pointer;
}
.rotate-icon{
    float:right;
  }
  .question-collapse .card-header:not(.not-collapsed) .rotate-icon{
    transform: rotate(180deg);
  }
</style>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import CourseService from '@/services/course.service.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BTable, BProgress,BButton, BContainer, BRow, BCol, BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BLink, BIcon,BCard,BCardHeader,BCardBody,VBToggle,BCollapse} from 'bootstrap-vue'
  export default {
    components: {
        BCardCode,
        BTable,
        BProgress,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BOverlay,
        BRow,
        BCol,
        BButton,
        BLink,
        BIcon,
        BContainer,
        BCard,
        BCardHeader,
        BCardBody,
        BCollapse
    },
    directives: {
        'b-toggle': VBToggle,
    },
    data() {
      return {
        months: [],
        lessons: {},
        showOverlay: false,
      }
    },
    methods: {
        getMonths()
        {
            this.showOverlay = true;
            CourseService.studentMonthlyVideo().then(response => {
                this.months = response.data.data.attachments
                this.showOverlay = false;
                console.log(response.data.data)
            }).catch(error => {
              this.showOverlay = false;
              this.errors = error.response
            });
        }
    },
    created ()
    {
        this.getMonths()
    }
  }
</script>